import React from "react";
import styled from "styled-components";

const TryButton = styled.button`
  width: 16rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  border: 0.1rem solid var(--gray-900);
  background: var(--gray-050);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
`;
const AddButton = styled.button`
  width: 16rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.2rem;
  background: var(--gray-900);
  color: var(--gray-050);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
`;

export const Try = () => {
  return <TryButton>TRY</TryButton>;
};
export const Add = () => {
  return <AddButton>ADD</AddButton>;
};
