import React from "react";
import { Route, Routes } from "react-router-dom";
import CelebPage from "./pages/CelebPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/celebs/:id" element={<CelebPage />} />
      </Routes>
    </div>
  );
}

export default App;
