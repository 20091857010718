import React from "react";
import { getCelebData } from "../../hooks/dummy";
//import CelebSummarySection from "./CelebSummarySection";
import HighlightSection from "./HighlightSection";
import styled from "styled-components";
import ReportSection from "./ReportSection";
import CelebNameSection from "./CelebNameSection";
import CelebInformationSection from "./CelebInformationSection";

export const SectionTitle = styled.h3`
  ${({ theme }) => theme.font.h3};
  ${({ theme }) => theme.font.bold};
`;

const Celeb = () => {
  const celebData = getCelebData();
  return (
    <>
      <CelebNameSection celebInfo={celebData.data.celebInfo} />
      <CelebInformationSection celebData={celebData.data} />
      {/*<CelebSummarySection celebData={celebData.data} />*/}
      <HighlightSection celebData={celebData.data} />
      <ReportSection celebData={celebData.data} />
    </>
  );
};

export default Celeb;
