import React, { ReactNode } from "react";
import styled from "styled-components";

interface ImageContainerProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  overflow?: string;
  objectFit?: string;
  children?: ReactNode;
}

interface ImageContainerStyleProps {
  $width?: string;
  $height?: string;
  $borderRadius?: string;
  $overflow?: string;
  $objectFit?: string;
}

const ImageContainerStyle = styled.div<ImageContainerStyleProps>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  overflow: ${(props) => props.$overflow};
  border-radius: ${(props) => props.$borderRadius};
  img {
    width: 100%;
    height: 100%;
    object-fit: ${(props) => props.$objectFit};
  }
`;

const ImageContainer = ({
  width,
  height,
  borderRadius = "0",
  overflow = "hidden",
  objectFit = "cover",
  children,
}: ImageContainerProps) => {
  return (
    <ImageContainerStyle
      $width={width}
      $height={height}
      $borderRadius={borderRadius}
      $overflow={overflow}
      $objectFit={objectFit}
    >
      {children}
    </ImageContainerStyle>
  );
};

export default ImageContainer;
