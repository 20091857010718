import React from "react";
import Section from "../layout/Section";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import { SectionTitle } from "./Celeb";
import { getHighlightData } from "../../hooks/dummy";

const HighlightList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 2.8rem;
`;

const Highlight = styled.li`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: 16rem;
  overflow: hidden;
  .title {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .year {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
  .highlight-thumbnail {
    position: relative;
    width: 16rem;
    height: 20rem;
    background-color: var(--gray-500);
    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .logo {
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      position: absolute;
      right: 0.8rem;
      bottom: 0.8rem;
      background-color: black;
      object-fit: contain;
    }
  }
`;

interface HighlightSectionProps {
  celebData: CelebData;
}

const HighlightSection = ({ celebData }: HighlightSectionProps) => {
  const data = getHighlightData().data;

  return (
    <Section mt="12rem">
      <SectionTitle>Highlights</SectionTitle>
      <HighlightList>
        {data.map((data, index) => {
          //const date = new Date(data.movieReleaseDate);
          //const year = date.getUTCFullYear();
          const year = data.movieReleaseDate.substring(0, 4);
          if (index < 8)
            return (
              <Highlight>
                <div className="highlight-thumbnail">
                  <img
                    className="thumbnail"
                    alt="thumbnail"
                    src={data.imgUrl}
                  />
                  <img className="logo" alt="logo" src={data.logoImg} />
                </div>
                <p className="title">{data.movieName}</p>
                <p className="year">{year}</p>
              </Highlight>
            );
          return <></>;
        })}
      </HighlightList>
    </Section>
  );
};

export default HighlightSection;
