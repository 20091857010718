import React from "react";
import styled from "styled-components";

const RequestProjectButton = styled.button`
  width: 42rem;
  height: 6rem;
  border-radius: 0.2rem;
  ${({ theme }) => theme.font.body1};
  color: var(--gray-050);
  background-color: var(--gray-500);
`;

const RequestProject = () => {
  return <RequestProjectButton>Request Project</RequestProjectButton>;
};

export default RequestProject;
