import React from "react";
import Section from "../layout/Section";
import styled from "styled-components";
import RequestProject from "../button/RequestProject";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import { CelebInfo } from "../interfaces/celeb.interfaces";
import { HashtagList } from "./Keyword";

const CelebName = styled.h1`
  font-size: 5.6rem;
  margin-top: 0.8rem;
  ${({ theme }) => theme.font.larken};
`;

const CelebTypeList = styled.div`
  display: flex;
  gap: 1.6rem;
`;

const CelebType = styled.span`
  color: var(--gray-600);
  margin-top: 0.4rem;
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.regular};
`;

const Wrapper = styled.div`
  display: flex;
  width: 144rem;
`;

const DefaultInformation = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const RequestProjectArea = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const ButtonAndFavorite = styled.div`
  display: flex;
  font-size: 4rem;
  align-items: center;
  gap: 2rem;
`;

const CartAndSearchMessage = styled.div`
  display: flex;
  font-size: 4rem;
  align-items: center;
  gap: 4rem;
  .search-message {
    cursor: pointer;
    color: var(--gray-500);
    font-size: 1.4rem;
    font-style: italic;
    font-weight: 400;
    line-height: 120%;
    text-decoration-line: underline;
  }
`;

const OtherInformation = styled.div`
  margin-top: 2.4rem;
  padding: 1.2rem 2.4rem;
  border-top: 1px solid var(--gray-800);
  border-bottom: 1px solid var(--gray-800);
  display: flex;
  align-items: center;
  gap: 4rem;
  .bold {
    ${({ theme }) => theme.font.bold}
  }
`;

//const KeywordList = styled.div`
//  display: flex;
//  gap: 0.8rem;
//  margin-top: 1.2rem;
//  padding-left: 2.4rem;
//  padding-right: 2.4rem;
//  color: var(--gray-600);
//`;

const SocialIconList = styled.div`
  display: inherit;
  font-size: 2.4rem;
  gap: 1.6rem;
  .material-icons {
    cursor: pointer;
  }
`;

interface CelebNameSectionProps {
  celebInfo: CelebInfo;
}

const CelebNameSection = ({ celebInfo }: CelebNameSectionProps) => {
  return (
    <Section mt="6.6rem">
      <Wrapper>
        <DefaultInformation>
          <HashtagList>
            {celebInfo.tag?.map((tag, index) => {
              return <li key={"hashtag" + tag + index}>{tag}</li>;
            })}
          </HashtagList>
          <CelebName>{celebInfo.name}</CelebName>
          <CelebTypeList>
            {celebInfo.keyword?.map((keyword, index) => {
              return (
                <CelebType key={"celebType_" + keyword + index}>
                  {keyword}
                </CelebType>
              );
            })}
          </CelebTypeList>
        </DefaultInformation>
        <RequestProjectArea>
          <ButtonAndFavorite>
            <RequestProject />
            <FavoriteBorderIcon fontSize="inherit" />
          </ButtonAndFavorite>
          <CartAndSearchMessage>
            <ShoppingCartOutlinedIcon fontSize="inherit" />
            <span className="search-message">
              If you can’t search the photo you want?
            </span>
          </CartAndSearchMessage>
        </RequestProjectArea>
      </Wrapper>
      <OtherInformation>
        <span>
          Active From : <span className="bold">{celebInfo.activeFrom}</span>
        </span>
        <span>
          Management : <span className="bold">{celebInfo.management}</span>
        </span>
        <SocialIconList>
          {celebInfo.webUrl ? (
            <a href={celebInfo.webUrl} target="_blank" rel="noreferrer">
              <LanguageIcon fontSize="inherit" />
            </a>
          ) : (
            <></>
          )}
          {celebInfo.instaUrl ? (
            <a href={celebInfo.instaUrl} target="_blank" rel="noreferrer">
              <InstagramIcon fontSize="inherit" />
            </a>
          ) : (
            <></>
          )}
          {celebInfo.youtubeUrl ? (
            <a href={celebInfo.youtubeUrl} target="_blank" rel="noreferrer">
              <YouTubeIcon fontSize="inherit" />
            </a>
          ) : (
            <></>
          )}
        </SocialIconList>
      </OtherInformation>
      {/*<KeywordList>
        {celebInfo.keyword?.map((data, index) => {
          return <li>{data}</li>;
        })}
      </KeywordList>*/}
    </Section>
  );
};

export default CelebNameSection;
